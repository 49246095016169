import React, { Fragment, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Dropdown, ListGroup, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "simplebar/dist/simplebar.min.css";
import { Link, useNavigate } from "react-router-dom";
import useMounted from "../../../../hooks/useMounted";
import { userLogout } from "../../../../store/authSlice";
import { ProfileIcon } from "../../master/navbar/profileSvgIcon/ProfileIcon";
import { WalletIcon } from "../../master/navbar/profileSvgIcon/WalletIcon";
import { LogoutIcon } from "../../master/navbar/profileSvgIcon/LogoutIcon";
import { Toast, ToastContainer } from "react-bootstrap";
import { CheckCircle } from "react-feather";

const NavbarTopMenu = () => {
  const hasMounted = useMounted();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [showLoader, setShowLoader] = useState(false);

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const user = useSelector((state) => state.auth.user)

  const handleLogOut = async () => {
    setShowLoader(true);
    try {
      await dispatch(userLogout()).then((res) => {
        setToastMessage(res.payload.message || "Logout successful");
        setToastVariant("success");
        setShowToast(true);
        setTimeout(() => {
          if (hasMounted) {
            navigate("/", { replace: true });
          }
        }, 2000);
      });
    } catch (error) {
      console.error("Logout failed:", error);
      setToastMessage("Logout failed");
      setToastVariant("danger");
      setShowToast(true);
      setTimeout(() => {
        if (hasMounted) {
          navigate("/", { replace: true });
        }
      }, 2000);
    } finally {
      setTimeout(() => {
        setShowLoader(false);
        if (hasMounted) {
          navigate("/", { replace: true });
        }
      }, 2000);
    }
  };

  const LoaderPage = () => (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        zIndex: "1",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <Spinner animation="border" role="status" variant="light">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );

  const getUserInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "U";
  };

  const NavbarTopMenuDesktop = () => {
    return (
      <>
      <div className="mx-4 my-2"><strong>{user?.name}</strong></div>
      <ListGroup
        as="ul"
        bsPrefix="navbar-nav"
        className="navbar-right-wrap ms-auto d-flex nav-top-wrap"
      >
        <Dropdown as="li" className="ms-2">
          <Dropdown.Toggle
            as="div"
            bsPrefix=" "
            className="rounded-circle"
            id="dropdownUser"
          >
            <div
              className="rounded-circle bg-primary d-flex justify-content-center align-items-center"
              style={{
                width: 40,
                height: 40,
                color: "white",
                fontSize: "1.25rem",
                cursor: "pointer"
              }}
            >
              {getUserInitial(user?.name)}
            </div>

            {/* <div className="avatar avatar-md avatar-indicators bg-primary rounded-circle justify-content-center align-items-center">
              <Image alt="avatar" src={avatar} className="rounded-circle" />
            </div> */}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu dropdown-menu-end "
            align="end"
            aria-labelledby="dropdownUser"
            show
          >
            <Dropdown.Item as="div" className="px-4 pb-0 pt-2" bsPrefix=" ">
              <div className="lh-1">
                <h5 className="mb-1 text-capitalize">{user?.name || user?.data?.name}</h5>{" "}
              </div>
              <div className="dropdown-divider mt-3 mb-2"></div>
            </Dropdown.Item>
            <Dropdown.Item as="div">
              <Link to="/profile">
                <ProfileIcon />
                My Profile
              </Link>
            </Dropdown.Item>
            <Dropdown.Item as="div">
              <Link to="/wallet">
                <WalletIcon />
                Wallet
              </Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogOut} as="div">
              <Link to="">
                <LogoutIcon />
                Log Out
              </Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ListGroup>
      </>
    );
  };

  return (
    <Fragment>
      {hasMounted && isDesktop ? <NavbarTopMenuDesktop /> : <LoaderPage />}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          bg={toastVariant}
        >
          <Toast.Body
            style={{ color: "white", display: "flex", alignItems: "center" }}
          >
            <CheckCircle size={20} color="white" style={{ marginRight: 8 }} />
            {toastMessage}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {showLoader && <LoaderPage />}
    </Fragment>
  );
};

export default NavbarTopMenu;
